<template>
  <div class="stat_params">
    <component
      :is="which_to_show"
      :params="params"
      @edit="edit"
      @back="back"
      @add="add"
    ></component>
  </div>
</template>
<script>
import statTable from "./component/statTable.vue";
import statComponent from "./component/statComponent.vue";
export default {
  components: {
    statTable,
    statComponent,
  },
  data() {
    return {
      which_to_show: "stat-table",
      params: null,
    };
  },
  methods: {
    edit(val) {
      this.params = val;
      this.which_to_show = "stat-component";
    },
    add() {
      this.which_to_show = "stat-component";
    },
    back() {
      this.params = null;
      this.which_to_show = "stat-table";
    },
  },
};
</script>
