<template>
  <div class="statParams">
    <el-form ref="form" label-position="top" :model="form">
      <div class="content" style="border: none">
        <div class="title"><span>*</span>新增统计参数</div>
        <el-row>
          <el-col :span="5">
            <el-form-item
              prop="level"
              label=""
              label-position="right"
              :rules="{
                validator: checklevel,
                trigger: 'change',
              }"
            >
              统计学段：<el-select v-model="form.level" placeholder="请选择">
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item
              prop="year"
              label=""
              label-position="right"
              :rules="{
                validator: checkyear,
                trigger: 'change',
              }"
            >
              <div style="display: flex">
                入学年份：
                <el-select v-model="form.year" placeholder="请选择">
                  <el-option
                    v-for="item in yearOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              prop="name"
              label=""
              label-position="right"
              :rules="{
                validator: checkname,
                trigger: ['change', 'input'],
              }"
            >
              <div style="display: flex">
                <span style="white-space: nowrap">参数名称：</span>
                <el-input
                  v-model="form.name"
                  placeholder="请输入模板名称"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="content">
        <div class="title">默认统计参数设置：</div>
        <div class="round-checkBox">
          <div class="radioGroup">
            <div
              v-for="(item, index) in statTypeList"
              :key="index"
              :class="
                form.baseType === index + 1 ? 'radio-item-active' : 'radio-item'
              "
              @click="radioChange(index + 1)"
            >
              <div class="point"></div>
              {{ item }}
            </div>
            <div v-if="form.baseType === 1">
              <el-checkbox
                v-model="form.excludeZero"
                :true-label="1"
                :false-label="0"
                >零分不参与统计（如果总分是“0”分，那么总分不参与统计；如果某个科目为“0”分，那么该科不参与统计）</el-checkbox
              >
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="title">上线设置：</div>
        <el-form-item
          prop="onlineType"
          :rules="{
            required: true,
            message: '请选择统计参数设置方式',
            trigger: 'change',
          }"
          class="noLine"
        >
          <el-radio-group v-model="form.onlineType" @change="form.onlines = []">
            <el-radio :label="1">按比例</el-radio>
            <el-radio :label="2">按人数</el-radio>
          </el-radio-group>
        </el-form-item>
        <div>
          <div v-if="form.onlineType === 1">
            <el-form-item
              v-for="(item, index) in form.onlines"
              :key="index"
              class="rowForm noLine errorDefine mb30"
              :prop="'onlines.' + index + '.value'"
              :rules="{
                validator: (rule, value, callback) =>
                  checkValueType(rule, value, callback, index),
                trigger: 'blur',
              }"
            >
              <el-input
                v-model="item.name"
                style="width: 129px"
                size="mini"
              ></el-input>
              <span> ：比例前</span>
              <el-input v-model="item.value" style="width: 129px"></el-input>
              <span>%</span>
              <i class="el-icon-delete" @click="delItem(index)"></i>
            </el-form-item>
          </div>
          <div v-if="form.onlineType === 2">
            <el-form-item
              v-for="(item, index) in form.onlines"
              :key="index"
              :prop="'onlines.' + index + '.value'"
              class="rowForm mb30"
              :rules="{
                validator: (rule, value, callback) =>
                  checkValueType(rule, value, callback, index),
                trigger: 'blur',
              }"
            >
              <el-input
                v-model="item.name"
                style="width: 129px"
                size="mini"
              ></el-input>
              <span> ： 人数前</span>
              <el-input v-model="item.value" style="width: 129px"></el-input>
              <span>名</span>
              <i class="el-icon-delete" @click="delItem(index)"></i>
            </el-form-item>
          </div>

          <el-button type="text" class="mb30" @click="addItem">+增加</el-button>
          <div v-if="form.onlineType === 2" class="tag mb30">
            <i class="el-icon-warning"></i>
            <span>单科有效分计算方式：</span>
            <div class="tagRadio">
              <el-form-item
                prop="subjectEpType"
                class="noLine noMargin"
                :rules="{
                  required: true,
                  message: '请选择单科有效分计算方式',
                  trigger: 'change',
                }"
              >
                <el-radio-group v-model="form.subjectEpType">
                  <el-radio :label="1">按单科成绩榜取上线人数</el-radio>
                  <el-radio :label="2"
                    >单科榜计算新总分榜，取总分有效分所在行</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="title">默认临界区间设置（分值）：</div>
        <div class="form-box">
          <el-form-item
            v-for="(item, index) in form.criticalIntervals"
            :key="index"
            :prop="'criticalIntervals.' + index + '.value'"
            style="margin-bottom: 18px"
            :rules="{
              required: true,
              validator: (rules, value, callback) =>
                validCritical(rules, value, callback, index),
              trigger: 'blur',
            }"
          >
            <div class="form-box-item" style="margin-bottom: 0">
              <div class="label text-overflow">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.label"
                  placement="top"
                >
                  <span>{{ item.label }} </span> </el-tooltip
                >：
              </div>
              <div class="input">
                <el-input v-model="item.value"></el-input>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="content">
        <div class="title"><span>*</span> 前N名区间设置</div>
        <el-form-item
          prop="topSection"
          :rules="{
            required: true,
            validator: checkTopSection,
            trigger: 'blur',
          }"
        >
          <div class="rowForm mb30 align-start">
            <div class="label">前N名区间：</div>
            <div>
              <el-input
                v-model="form.topSection"
                style="width: 520px"
              ></el-input>
              <div class="ps">
                以","分隔，如：输入"10,50,100"将对前10名、前50名、前100名进行统计；
              </div>
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="content">
        <div class="title"><span>*</span>分数段区间设置</div>
        <el-form-item
          prop="totalSegmentMinimum"
          label="总分分数段"
          :rules="{
            validator: checkTotalScore,
            trigger: 'blur',
          }"
        >
          <div class="rowForm gg18">
            <div class="rowForm-item">
              <div class="label">上限：</div>
              <el-input
                v-model="form.totalSegmentMaximum"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                style="width: 140px"
                @blur="form.totalSegmentMaximum = $event.target.value"
              >
                <div slot="append">%</div>
              </el-input>
            </div>
            <div class="rowForm-item">
              <div class="label">下限：</div>
              <el-input
                v-model="form.totalSegmentMinimum"
                oninput="value=value.replace(/[^0-9.]/g,'')"
                style="width: 140px"
                @blur="form.totalSegmentMinimum = $event.target.value"
              >
                <div slot="append">%</div>
              </el-input>
            </div>
            <div class="rowForm-item">
              <div class="label">间隔：</div>
              <el-input
                v-model="form.totalSegmentGap"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width: 140px"
                @blur="form.totalSegmentGap = $event.target.value"
              >
                <div slot="append">分</div>
              </el-input>
            </div>
          </div>
        </el-form-item>
        <div class="ps margin30">
          以百分比形式输入分数段区间的上下限和间隔，如下限输入"40"，上限输入"90"，间隔输入"10"，则统计得分在"40%*满分—90%*满分"(全舍取整)之间的分数段，
          每隔10分为段，由高到低开始取。
          例如满分为750分，则统计"300-675"之间的分数段："665-675,655-665,...,305-315,300-305"，包含下限，不包含上限。
        </div>
        <!-- <div class="title"></div> -->
        <div class="mb30">
          <el-form-item
            prop="subjectSegmentMaximum"
            label="单科分数段"
            :rules="{
              validator: checkSubjectScore,
              trigger: 'blur',
            }"
          >
            <div class="rowForm">
              <div class="rowForm-item">
                <div class="label">上限：</div>
                <el-input
                  v-model="form.subjectSegmentMaximum"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  style="width: 140px"
                  @blur="form.subjectSegmentMaximum = $event.target.value"
                >
                  <div slot="append">%</div>
                </el-input>
              </div>
              <div class="rowForm-item">
                <div class="label">下限：</div>
                <el-input
                  v-model="form.subjectSegmentMinimum"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  style="width: 140px"
                  @blur="form.subjectSegmentMinimum = $event.target.value"
                >
                  <div slot="append">%</div>
                </el-input>
              </div>
              <div class="rowForm-item">
                <div class="label">间隔：</div>
                <el-input
                  v-model="form.subjectSegmentGap"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  style="width: 140px"
                  @blur="form.subjectSegmentGap = $event.target.value"
                >
                  <div slot="append">分</div>
                </el-input>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="mb30">
        <div class="title mb30">排名区间设置</div>
        <div class="mb30">
          <el-form-item
            prop="rankMaximum"
            style="margin-right: 10px"
            label=""
            :rules="{
              validator: checkRank,
              trigger: 'blur',
            }"
          >
            <div class="rowForm">
              <div class="rowForm-item">
                <div class="label">上限：</div>
                <el-input
                  v-model="form.rankMaximum"
                  style="width: 180px"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @blur="form.rankMaximum = $event.target.value"
                >
                  <div slot="append">名</div>
                </el-input>
              </div>
              <div class="rowForm-item">
                <div class="label">下限：</div>
                <el-input
                  v-model="form.rankMinimum"
                  style="width: 180px"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  @blur="form.rankMinimum = $event.target.value"
                >
                  <div slot="append">名</div>
                </el-input>
              </div>
              <div class="rowForm-item">
                <div class="label">间隔：</div>
                <el-input
                  v-model="form.rankGap"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  style="width: 180px"
                  @blur="form.rankGap = $event.target.value"
                >
                  <div slot="append">名</div>
                </el-input>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="content">
        <div class="title">
          <span>*</span>各种比率设置（高分率、优秀率、及格率、低分率）
        </div>
        <el-radio-group
          v-model="form.ratioType"
          style="margin-bottom: 24px"
          @change="ratioTypeChange"
        >
          <el-radio :label="1">按分数比例</el-radio>
          <el-radio :label="2">按人数比例</el-radio>
        </el-radio-group>
        <div class="border-box mb30">
          <el-form-item
            v-for="(item, index) in form.ratios"
            :key="item.label"
            label=""
            :prop="'ratios.' + index + '.value'"
            class="noLine"
            :rules="{
              validator: (rule, value, callback) =>
                checkRatioType(rule, value, callback, index),
              trigger: 'change',
            }"
          >
            <div class="rowForm">
              <el-input
                v-model="item.name"
                style="width: 90px"
                size="mini"
              ></el-input>
              <span> 率，</span>
              <div>
                <el-select
                  v-model="item.type"
                  style="width: 90px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="it in options"
                    :key="it.value"
                    :label="it.label"
                    :value="it.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <span>:</span>
              <div>
                <el-input
                  v-model.number="item.value"
                  style="width: 90px"
                ></el-input>
              </div>
              <span>%</span>
              <i class="el-icon-delete" @click="delRatioItem(index)"></i>
            </div>
          </el-form-item>
          <div class="ps mb30">
            例如"低分率"输入"40"%，类型选择 分数＜
            ，则计算得分小于"40%*满分"的人数占总人数的百分比
          </div>
          <el-button type="primary" class="mb30" @click="addRatioItem"
            >+新增</el-button
          >
        </div>
      </div>
      <div class="content">
        <div class="title">等级区间设置：</div>
        <div class="rowForm mb20">
          <el-switch
            v-model="form.levelSegmentSwitch"
            :active-value="1"
            :inactive-value="0"
            active-text="开启等级区间设置"
            inactive-text="关闭等级区间设置"
          >
          </el-switch>
        </div>

        <el-form-item v-if="form.levelSegmentSwitch == 1" label="">
          <el-radio-group v-model="form.levelSegmentType">
            <el-radio :label="1">按分数比例</el-radio>
            <el-radio :label="2">按人数比例</el-radio>
          </el-radio-group>
          <div v-if="form.levelSegmentType === 1" class="ps mb30">
            <span>按分数比例字段提示：</span
            >以","分隔多个等级名称和等级百分比，等级百分比与等级的名称一一对应。如：等级的名称输入"A,B,C"，等级百分比输入"90,80"。计算规则为：①计算满分*90%的有效分=n1，则满分≥考生分数≥n1，记为“A”；②计算满分*80%的有效分=n2，则n1＞考生分数≥n2，记为“B”；③n2＞考生分数≥0分，记为“C”
          </div>
          <div v-if="form.levelSegmentType === 2" class="ps mb30">
            <span>按人数计算字段提示：</span>
            以","分隔多个等级名称和等级百分比，等级百分比与等级的名称一一对应。如：等级的名称输入"A,B,C"，等级百分比输入"10,20"。计算规则为：①计算排名第10%的考生（小数取整）的有效分=n1，则满分≥考生分数≥n1，记为“A”；②计算排名第20%的考生（小数取整）的有效分=n2，则n1＞考生分数≥n2，记为“B”；③n2＞考生分数≥0分，记为“C”
          </div>
          <div class="rowForm wrap">
            <el-form-item
              v-for="(item, index) in form.levelSegments"
              :key="index"
              :prop="'levelSegments.' + index + '.value'"
              class="mb24"
              :rules="{
                validator: (rule, value, callback) =>
                  checkLevelSegment(rule, value, callback, index),
              }"
            >
              <div class="level-item">
                <div class="mb level-item-part">
                  <div class="level_label">学科</div>
                  <div class="level_name">等级名称</div>
                  <div class="level_per">等级百分比</div>
                </div>
                <div class="level-item-part">
                  <div
                    :class="[
                      'level_label',
                      item.subjectId === 0 ? 'total' : '',
                    ]"
                  >
                    {{ item.label }}
                  </div>
                  <div class="level_name">
                    <el-input v-model="item.name"></el-input>
                  </div>
                  <div class="level_per">
                    <el-input v-model="item.value">
                      <span slot="suffix">%</span>
                    </el-input>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
        </el-form-item>
      </div>
      <div class="content">
        <div class="title">学生答题卡打印设置：</div>
        <el-form-item>
          <el-checkbox
            v-model="form.sheetPrintScore"
            :true-label="1"
            :false-label="0"
            >分值</el-checkbox
          >
          <el-checkbox
            v-model="form.sheetPrintTeacher"
            :true-label="1"
            :false-label="0"
            >阅卷教师</el-checkbox
          >
          <el-checkbox
            v-model="form.sheetPrintNotes"
            :true-label="1"
            :false-label="0"
            >批注信息</el-checkbox
          >
          <el-checkbox
            v-model="form.sheetPrintObjectNotes"
            :true-label="1"
            :false-label="0"
            >客观题批注信息</el-checkbox
          >
        </el-form-item>
      </div>
      <div class="content">
        <div class="title">学生排名是否显示：</div>
        <el-radio-group
          v-model="form.isDisplayRank"
          style="margin-bottom: 24px"
        >
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </div>
      <div class="content">
        <div class="title">班级排序设置：</div>
        <div class="round-checkBox">
          <div class="radioGroup">
            <div
              v-for="(item, index) in classNumOrderList"
              :key="index"
              :class="
                form.classNumOrder === index + 1
                  ? 'radio-item-active'
                  : 'radio-item'
              "
              @click="classNumOrderChange(index + 1)"
            >
              <div class="point"></div>
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <div class="submit">
      <el-button v-loading="submitLoading" type="primary" @click="submit"
        >提交</el-button
      >
      <el-button type="primary" @click="goback">返回</el-button>
    </div>
  </div>
</template>
<script>
import {
  getSubject,
  saveschoolstatparam,
  insertschoolstatparam,
} from "@/core/api/newOther";
import { levelOptions } from "@/core/util/constdata";
import { getdefaultstatparam } from "@/core/api/academic/common";
import { getYear } from "@/core/util/util";
export default {
  name: "StatParams",
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const checkLevelSegment = (rule, value, callback, index) => {
      const { name } = this.form.levelSegments[index];
      const reg = /^([\u0391-\uFFE5\d\w,])*([\u0391-\uFFE5\d\w]+)$/;
      const regNum = /^([\d.\d,])*([\d.\d]+)$/;
      if (name && value) {
        if (!reg.test(name) || !regNum.test(value)) {
          callback(new Error("请按照说明格式填写"));
        } else {
          let nameArr = name.split(",");
          let valueArr = value.split(",");
          if (nameArr.length - 1 != valueArr.length) {
            callback(new Error("名称和百分比没能相互对应"));
          } else {
            const errorArr = valueArr.filter((item, index) => {
              if (index + 1 < valueArr.length) {
                if (this.form.levelSegmentType == 1) {
                  if (Number(item) < Number(valueArr[index + 1])) {
                    return item;
                  }
                } else if (this.form.levelSegmentType == 2) {
                  if (Number(item) > Number(valueArr[index + 1])) {
                    return item;
                  }
                }
              }
            });
            if (errorArr.length) {
              if (this.form.levelSegmentType == 1) {
                callback(new Error("名称和百分比没能相互对应，应由大到小"));
              } else if (this.form.levelSegmentType == 2) {
                callback(new Error("名称和百分比没能相互对应，应由小到大"));
              }
            } else {
              callback();
            }
          }
        }
      } else {
        callback();
      }
    };
    const checkValueType = (rule, value, callback) => {
      const { onlineType } = this.form;
      let reg = null;
      if (onlineType === 1) {
        reg = /^[0-9]+(.[0-9]{2})?$/;
        if (!reg.test(value)) {
          callback(new Error("比例为数字且保留两位小数"));
        } else {
          callback();
        }
      } else if (onlineType === 2) {
        reg = /(^[1-9]\d*$)/;
        if (!reg.test(value)) {
          callback(new Error("人数只能为正整数"));
        } else {
          callback();
        }
      }
    };
    const checkTopSection = (rule, value, callback) => {
      let reg = /^([0-9,])*([0-9]+)$/;
      if (!value) {
        callback(new Error("请输入前N名区间"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请输入正确格式"));
        } else {
          callback();
        }
      }
    };

    const checkTotalScore = (rule, value, callback) => {
      if (
        this.form.totalSegmentMinimum &&
        this.form.totalSegmentMaximum &&
        this.form.totalSegmentGap
      ) {
        if (
          this.form.totalSegmentMinimum < 0 ||
          this.form.totalSegmentMinimum < 0 ||
          this.form.totalSegmentGap < 0
        ) {
          callback(new Error("参数只能为正整数"));
        } else if (
          this.form.totalSegmentMinimum >= 100 ||
          this.form.totalSegmentMaximum >= 100
        ) {
          callback(new Error("上限和下限不能超过100%"));
        } else if (
          this.form.totalSegmentMinimum >= this.form.totalSegmentMaximum
        ) {
          callback(new Error("上线必须大于下线"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请完善参数"));
      }
    };
    const checkSubjectScore = (rule, value, callback) => {
      if (
        this.form.subjectSegmentMinimum &&
        this.form.subjectSegmentMaximum &&
        this.form.subjectSegmentGap
      ) {
        if (
          this.form.subjectSegmentMinimum < 0 ||
          this.form.subjectSegmentMaximum < 0 ||
          this.form.subjectSegmentGap < 0
        ) {
          callback(new Error("参数只能为正整数"));
        } else if (
          this.form.subjectSegmentMinimum >= 100 ||
          this.form.subjectSegmentMaximum >= 100
        ) {
          callback(new Error("上限和下限不能超过100%"));
        } else if (
          this.form.subjectSegmentMinimum >= this.form.subjectSegmentMaximum
        ) {
          callback(new Error("上线必须大于下线"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请完善参数"));
      }
    };
    const checkRatioType = (rule, value, callback, index) => {
      setTimeout(() => {
        const { name, value: val } = this.form.ratios[index];
        const reg = /(^[1-9]\d*$)/;
        if (name && val) {
          if (!reg.test(val) || val > 100) {
            callback(new Error("比率只能是大于0小于100的正数"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请按顺序填写比率"));
        }
      }, 100);
    };
    return {
      shcoolId: "",
      dialogVisible: false,
      checkTotalScore: checkTotalScore,
      checkSubjectScore: checkSubjectScore,
      checkRatioType: checkRatioType,
      checkTopSection: checkTopSection,
      checkValueType: checkValueType,
      checkLevelSegment: checkLevelSegment,
      subjectOptions: [],
      form: {
        baseType: 1,
        onlineType: 1,
        excludeZero: 1,
        classNumOrder: 2,
        isDisplayRank: 1,
        levelSegmentSwitch: 0,
        criticalIntervals: [],
        ratioType: 1,
        totalSegmentMaximum: 80,
        totalSegmentMinimum: 30,
        totalSegmentGap: 10,
        subjectSegmentMaximum: 90,
        subjectSegmentMinimum: 30,
        subjectSegmentGap: 5,
        rankMinimum: null,
        rankMaximum: null,
        rankGap: null,
        onlines: [
          {
            name: 30,
          },
          {
            name: 60,
          },
          {
            name: 90,
          },
        ],
        ratios: [
          {
            name: "高分率",
            type: 1,
            value: 80,
          },
          {
            name: "优秀率",
            type: 1,
            value: 70,
          },
          {
            name: "及格率",
            type: 1,
            value: 60,
          },
          {
            type: 1,
            name: "低分率",
            value: 30,
          },
        ],
        levelSegmentType: 2,
        levelSegments: [],
        subjectEpType: 1,
        sheetPrintScore: 1,
        sheetPrintTeacher: 1,
        sheetPrintNotes: 1,
        sheetPrintObjectNotes: 1,
      },
      submitLoading: false,
      options: [
        {
          label: "分数≥",
          value: 1,
        },
        {
          label: "分数＜",
          value: 2,
        },
      ],
      statOnlineEpSettings: [],
      statTypeList: ["去掉不参与统计的人", "固定统计人数"],
      classNumOrderList: [
        "字符串排序",
        "先数字，再字符排序",
        "先字符，再数字排序",
      ],
      statParams: null,
      levelOptions: levelOptions(),
      yearOption: getYear(),
    };
  },
  mounted() {
    this.getParams();
    this.getDefaultData();
  },
  methods: {
    goback() {
      this.$emit("back");
    },
    checklevel(rule, value, callback) {
      if (value) {
        callback();
      } else {
        callback(new Error("请选择学段"));
      }
    },
    checkyear(rule, value, callback) {
      if (value) {
        callback();
      } else {
        callback(new Error("请选择入学年份"));
      }
    },
    checkname(rule, value, callback) {
      if (value) {
        callback();
      } else {
        callback(new Error("请输入参数名称"));
      }
    },
    validCritical(rule, values, callback, index) {
      const { value } = this.form.criticalIntervals[index];
      let reg = /^([0-9,])*([0-9]+)$/;
      if (!value) {
        callback(new Error("请填写默认临界区间"));
      } else if (Number(value) === 0) {
        callback(new Error("默认临界区间不能为0"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("格式不正确，分值只能为数字"));
        } else {
          callback();
        }
      }
    },
    checkRank(rule, value, callback) {
      if (this.form.rankMinimum === null) {
        callback(new Error("请输入排名区间设置-下限"));
      } else if (this.form.rankMaximum === null) {
        callback(new Error("请输入排名区间设置-上限"));
      } else if (this.form.rankGap === null) {
        callback(new Error("请输入排名区间设置-间隔（正整数）"));
      } else if (
        Number(this.form.rankMinimum) <= Number(this.form.rankMaximum)
      ) {
        callback(new Error("排名区间下限必须大于上限"));
      } else if (
        Number(this.form.rankMinimum) <= 0 ||
        Number(this.form.rankMaximum) <= 0 ||
        Number(this.form.rankGap) <= 0
      ) {
        callback(new Error("排名区间必须大于0"));
      } else {
        callback();
      }
    },
    async getSubjectList() {
      const res = await getSubject();
      this.subjectOptions = res.data.data;
      let arr = res.data.data.map((item) => {
        return {
          label: item.subjectName,
          name: "",
          value: 0,
          subjectId: item.subjectId,
        };
      });
      const arrClone = JSON.parse(JSON.stringify(arr));
      let criticalIntervalsSubject = this.form.criticalIntervals.map(
        (item) => item.subjectId
      );
      let diffArr = arr.filter(
        (item) => !criticalIntervalsSubject.includes(item.subjectId)
      );
      const allSubjectIdS = res.data.data.map((item) => item.subjectId);
      const formCriticalIntervals = this.form.criticalIntervals.filter(
        (item) => allSubjectIdS.includes(item.subjectId) || item.subjectId == 0
      );
      let subjectArr = [...formCriticalIntervals, ...diffArr];
      if (subjectArr.some((item) => item.label == "总分")) {
        this.form.criticalIntervals = [...subjectArr];
      } else {
        this.form.criticalIntervals = [
          {
            label: "总分",
            name: "",
            value: 0,
            subjectId: 0,
          },
          ...subjectArr,
        ];
      }

      this.form.levelSegments = [
        {
          label: "总分",
          name: "",
          value: "",
          subjectId: 0,
        },
        ...arrClone,
      ];
    },
    ratioTypeChange() {
      if (this.form.ratioType === 1) {
        this.options = [
          {
            label: "分数≥",
            value: 1,
          },
          {
            label: "分数＜",
            value: 2,
          },
        ];
      } else {
        this.options = [
          {
            label: "人数前",
            value: 1,
          },
          {
            label: "人数后",
            value: 2,
          },
        ];
      }
    },
    radioChange(index) {
      this.form.baseType = index;
      if (this.form.baseType === 2) {
        this.form.excludeZero = 0;
      }
    },
    classNumOrderChange(index) {
      this.form.classNumOrder = index;
    },
    addItem() {
      if (this.form.onlineType) {
        this.form.onlines.push({
          name: "",
          value: "",
        });
      } else {
        this.$message.error("请选择统计参数设置方式");
      }
    },
    delItem(index) {
      this.form.onlines.splice(index, 1);
    },
    delRatioItem(index) {
      this.form.ratios.splice(index, 1);
    },
    addRatioItem() {
      this.form.ratios.push({
        name: "",
        type: 1,
        value: "",
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.setParams();
        } else {
          this.$nextTick(() => {
            let errorNodes = document.querySelectorAll(".is-error");
            // console.log(errorNodes);
            let top =
              errorNodes[0].offsetTop - 100 > 0
                ? errorNodes[0].offsetTop - 100
                : 0;
            window.scrollTo({
              top: top,
              behavior: "smooth",
            });
          });
        }
      });
    },
    async getDefaultData() {
      const res = await getdefaultstatparam();

      let data = JSON.parse(res.data.data);
      this.form = {
        ...data,
        ...this.form,
      };
      this.$refs.form.clearValidate();
      this.getSubjectList();
    },
    async getParams() {
      if (this.params) {
        this.statParams = {
          id: this.params.id,
          level: this.params.level.toString(),
          year: this.params.year,
          name: this.params.name,
          isDefault: this.params.isDefault,
        };
        this.form = {
          ...this.params.param,
          level: this.params.level.toString(),
          year: this.params.year,
          name: this.params.name,
          isDefault: this.params.isDefault,
        };
        this.$refs.form.clearValidate();
        this.getSubjectList();
      } else {
        this.getDefaultData();
      }
    },
    async setParams() {
      this.submitLoading = true;
      if (this.params) {
        try {
          await saveschoolstatparam({
            name: this.form.name,
            level: this.form.level,
            year: this.form.year,
            id: this.statParams.id,
            isDefault: this.params.isDefault,
            param: JSON.stringify(this.form),
          });
          this.submitLoading = false;
          this.$emit("back");
          this.$message.success("保存成功");
        } catch {
          this.submitLoading = false;
        }
      } else {
        try {
          await insertschoolstatparam({
            name: this.form.name,
            level: this.form.level,
            year: this.form.year,
            isDefault: 1,
            param: JSON.stringify(this.form),
          });
          this.submitLoading = false;
          this.$emit("back");
          this.$message.success("保存成功");
        } catch {
          this.submitLoading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.statParams {
  .content {
    box-sizing: border-box;
    // border-bottom: 1px solid #dfdfdf;
    margin-bottom: 24px;
    .title {
      font-size: 14px;
      color: #080a09;
      font-weight: 550;
      margin-bottom: 24px;
      position: relative;
      box-sizing: border-box;
      padding-left: 8px;
      &::before {
        position: absolute;
        content: "";
        width: 2px;
        height: 12px;
        background: #2474ed;
        border-radius: 2px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      span {
        color: #ff0000;
      }
    }
    .round-checkBox {
      margin-bottom: 24px;
      .el-checkbox {
        margin-bottom: 0;
        ::v-deep .el-checkbox__inner {
          border-radius: 50% !important;
          overflow: hidden;
        }
      }
      .radioGroup {
        display: flex;
        align-items: center;
        .radio-item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          color: #5b625e;
          font-size: 14px;
          cursor: pointer;
          .point {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;
            margin-right: 8px;
          }
        }
        .radio-item-active {
          display: flex;
          align-items: center;
          margin-right: 20px;
          color: #080a09;
          font-size: 14px;
          cursor: pointer;
          .point {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #2474ed;
            box-sizing: border-box;
            margin-right: 8px;
            position: relative;
            &::after {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #fff;
              content: "";
            }
          }
        }
      }
    }
    .form-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .form-box-item {
        display: flex;
        align-items: center;
        margin-right: 24px;
        margin-bottom: 24px;
        border: 1px dashed #d8d8d8;
        .label {
          width: 101px;
          height: 40px;
          line-height: 40px;
          text-align: right;
          border-right: 1px dashed #d8d8d8;
          box-sizing: border-box;
          padding-left: 5px;
        }
        .text-overflow {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .input {
          height: 40px;
          line-height: 40px;
          width: 101px;
          padding: 0 5px;
          input {
            margin-left: 9px;
          }
        }
      }
    }
    .borderBottomBox {
      box-sizing: border-box;
      padding-bottom: 30px;
      margin-bottom: 24px;
      line-height: 1;
    }
    .rowForm {
      display: flex;
      align-items: center;
      // margin-bottom: 24px;
      .rowForm-item {
        display: flex;
        align-items: center;
        margin-right: 15px;
      }
      span {
        margin: 0 15px;
      }
      i {
        margin: 0 8px;
        cursor: pointer;
        &:hover {
          color: #f56c6c;
        }
      }
      .label {
        white-space: nowrap;
        margin-right: 8px;
      }
    }
    .tag {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 12px;
      border: 1px dashed #2474ed;
      background-color: #f2f8fe;
      font-style: 14px;
      flex-wrap: wrap;
      color: #737677;
      i {
        color: #2474ed;
        margin-right: 10px;
      }
      span {
        margin-right: 25px;
      }
      .tagRadio {
        margin-right: 25px;
      }
      .algorithm-link {
        color: #2474ed;
        cursor: pointer;
      }
    }
  }
}

.level-item {
  font-style: 14px;
  color: #080a09;
  text-align: center;
  // margin-bottom: 24px;
  margin-right: 15px;
  .mb {
    margin-bottom: 8px;
  }
  .level-item-part {
    display: flex;
    align-items: center;
    height: 50px;
    .total {
      color: #ff0000;
    }
    .level_label {
      line-height: 50px;
      width: 88px;
      border: 1px dotted #dad9d9;
    }
    .level_name {
      width: 160px;
      border-top: 1px dotted #dad9d9;
      border-bottom: 1px dotted #dad9d9;
      line-height: 50px;
      box-sizing: border-box;
      padding: 0 23px;
    }
    .level_per {
      width: 160px;
      line-height: 50px;
      box-sizing: border-box;
      padding: 0 23px;
      border: 1px dotted #dad9d9;
    }
  }
}
.el-form-item {
  margin-bottom: 0;
}
.mb24 {
  margin-bottom: 24px;
}
.mb30 {
  margin-bottom: 24px;
}
.margin30 {
  margin: 30px 0;
}
.wrap {
  flex-wrap: wrap;
}
.noMargin {
  margin: 0;
}
.align-start {
  align-items: flex-start !important;
}
.ps {
  color: #b8b8b8;
  font-size: 14px;
}
.submit {
  text-align: center;
  margin: 24px 0;
}
.rowForm {
  display: flex;
  align-items: center;

  .rowForm-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }

  span {
    margin: 0 8px;
  }

  i {
    cursor: pointer;
    color: #f56c6c;
  }

  .label {
    white-space: nowrap;
    margin-right: 8px;
  }
}
.mb20 {
  margin-bottom: 20px;
}
</style>
