<template>
  <div v-loading="loading" class="stat-table">
    <div class="header">
      <div class="filter">
        <filter-component
          :options="filterOptions"
          @change="getFilterValue"
        ></filter-component>
      </div>
      <el-button type="primary" @click="add">添加</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="name" label="模板名称" align="center">
        </el-table-column>
        <el-table-column prop="level" label="学段" align="center">
          <template slot-scope="{ row }">
            {{ getLevelName(row.level) }}
          </template>
        </el-table-column>
        <el-table-column prop="year" label="入学年份" align="center">
        </el-table-column>
        <el-table-column label="默认模板" align="center">
          <template slot-scope="{ row }">
            <el-button
              v-if="row.isDefault == 0"
              type="text"
              @click="setting(row)"
              >设置</el-button
            >
            <el-button v-else type="text" class="warning" @click="cancle(row)"
              >取消</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="edit(row)">编辑</el-button>
            <el-button type="text" class="warning" @click="del(row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        :current-page="search.current"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="search.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import filterComponent from "@/components/filter";
import { levelOptions } from "@/core/util/constdata";
import { getYear } from "@/core/util/util";
import {
  schoolstatparampage,
  deleteschoolstatparam,
  changeschoolstatparamisdefault,
} from "@/core/api/newOther";
export default {
  components: {
    filterComponent,
  },
  data() {
    return {
      search: {
        current: 1,
        size: 20,
      },
      total: 0,
      filterOptions: [
        {
          label: "学段",
          field: "level",
          type: "select",
          placeholder: "全部学段",
          options: levelOptions(),
          labelKey: "label",
          valueKey: "value",
        },
        {
          label: "年份",
          type: "select",
          field: "year",
          placeholder: "全部年份",
          options: [...getYear()],
          labelKey: "label",
          valueKey: "value",
        },
        {
          label: "",
          type: "input",
          field: "name",
          placeholder: "搜索模板名称",
        },
      ],
      loading: false,
      tableData: [],
      levelOptions: levelOptions(),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleSizeChange(size) {
      this.search.size = size;
      this.getData();
    },
    handleCurrentChange(current) {
      this.search.current = current;
      this.getData();
    },
    getLevelName(level) {
      const data = this.levelOptions.find(
        (item) => Number(item.value) == level
      );
      if (data) return data.label;
    },
    add() {
      this.$emit("add");
    },
    getFilterValue(val) {
      this.search = {
        current: 1,
        size: this.search.size,
        ...val,
      };
      this.getData();
    },
    async getData() {
      this.loading = true;
      try {
        const res = await schoolstatparampage({ ...this.search });
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    edit(row) {
      const data = {
        ...row,
        param: JSON.parse(row.param),
      };
      this.$emit("edit", data);
    },
    del(row) {
      this.$confirm("操作将永久参数模版信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            await deleteschoolstatparam({ id: row.id });
            this.getData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } catch {
            this.$message({
              type: "info",
              message: "删除失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async setting(row) {
      try {
        await changeschoolstatparamisdefault({
          id: row.id,
          isDefault: 1,
        });
        this.getData();
        this.$message({
          showClose: true,
          message: "已设置为默认",
          type: "success",
        });
      } catch {
        this.$message({
          showClose: true,
          message: "修改失败",
          type: "info",
        });
      }
    },
    async cancle(row) {
      try {
        await changeschoolstatparamisdefault({
          id: row.id,
          isDefault: 0,
        });
        this.getData();
        this.$message({
          showClose: true,
          message: "已取消默认",
          type: "success",
        });
      } catch {
        this.$message({
          showClose: true,
          message: "修改失败",
          type: "info",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.stat-table {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    .filter {
      display: flex;
      align-items: center;
    }
  }
  .warning {
    color: #f56c6c;
  }
}
</style>
